import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import AgreedLogo from '../../static/assets/newFooterLogo.svg';
import VectorMobileMenu from '../../static/assets/vectotSubmenuClose.svg';
const Header = props => {
  const { location, isTransparent } = props;
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    setMenu(false);
  }, [location]);
  useEffect(() => {
    if (document !== 'undefined') {
    }
  }, [menu]);
  const handleClickBars = () => {
    setMenu(!menu);
  };
  const handleLangSwitchLogin = () => {
    window.location.assign('https://app.agreed.io/login');
  };
  return (
    <header className={`header ${isTransparent ? 'header-transparent' : ''}`}>
      <div className="full-container overflow-visible">
        <nav
          className={`navbar-warpper ${
            isTransparent ? 'navbar-warpper-transparent' : ''
          }`}
        >
          <Link
            className="nav-item-link navbar-warpper-image"
            to={`/`}
            aria-label="icon"
          >
            <AgreedLogo className="navbar-warpper-image" />
          </Link>
          <div className={`navbar-list ${menu && 'active'}`}>
            <ul className={`navbar-list-item`}>
              <li className="nav-item nav-item-pricing">
                <Link
                  className={`nav-item-link ${
                    isTransparent ? 'nav-item-link-white' : ''
                  }`}
                  to={`/change-management-process`}
                >
                  Product
                </Link>
                <div className="border-gradient" />
              </li>
              <li className="nav-item nav-item-pricing">
                <Link
                  className={`nav-item-link ${
                    isTransparent ? 'nav-item-link-white' : ''
                  }`}
                  to={`/innovation-management-process`}
                >
                  Solutions
                </Link>
                <div className="border-gradient" />
              </li>
              <li className="nav-item nav-item-pricing">
                <Link
                  className={`nav-item-link ${
                    isTransparent ? 'nav-item-link-white' : ''
                  }`}
                  to={`/contact`}
                >
                  Pricing
                </Link>
                <div className="border-gradient" />
              </li>
              <li className="dropdown">
                <div className="menu">
                  <div className="menu-button">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Link
                        className={`nav-item-link case-study-link ${
                          isTransparent ? 'nav-item-link-white' : ''
                        }`}
                        to={'/'}
                      >
                        Resources
                      </Link>
                      <div className="menu-button-vector">
                        <VectorMobileMenu
                          style={{ width: '20px', height: '15px' }}
                        />
                      </div>
                    </div>

                    <div className="sub-menu">
                      <ul>
                        <li className="nav-item sub-menu-list">
                          <Link
                            className={`nav-item-link  sub-menu-link ${
                              isTransparent ? 'nav-item-link-white' : ''
                            }`}
                            to={'/our-blog'}
                          >
                            Blog
                          </Link>
                          <div className="border-gradient" />
                        </li>
                        <li className="sub-menu-list">
                          <Link
                            className={`nav-item-link  sub-menu-link ${
                              isTransparent ? 'nav-item-link-white' : ''
                            }`}
                            to={'/contact'}
                          >
                            Contact
                          </Link>
                          <div className="border-gradient" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div
              className={`loginButton ${
                isTransparent ? 'lang-change-white mobile-button' : ''
              }`}
            >
              <button
                aria-label="login"
                onClick={handleLangSwitchLogin}
                onKeyDown={handleLangSwitchLogin}
                type="button"
              >
                Log In
              </button>
            </div>
            <div
              className={`lang-change ${
                isTransparent ? 'lang-change-white' : ''
              }`}
            >
              <a className="lang-change " href="https://app.agreed.io/signup">
                Get Started
              </a>
            </div>
          </div>
          <div className="mobile-nav">
            <div className="bars">
              <button
                aria-label="button"
                onClick={handleClickBars}
                className={`hamburger--squeeze ${menu ? 'is-active' : ''}`}
                type="button"
              >
                <span className="hamburger-box">
                  <span
                    className={`hamburger-inner ${
                      isTransparent ? 'hamburger-inner-white' : ''
                    }`}
                  />
                </span>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
