/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import Seo from './seo';
import { client } from '../utilis/apolloClient';
import PopUpComponent from './popUpComponent';

const Layout = ({ children, pageContext, data, location, title, seo, isLandingPage }) => {
  const yoastHead = data?.wordpressPage?.yoast_head;
  const lang = pageContext?.lang;
  //   const options = data?.wordpressAcfOptions?.options;
  //   const cookieBanner = pageContext?.cookieBanner;
console.log(seo.base64);
  return (
    <ApolloProvider client={client}>
      <div className="layout">
        <PopUpComponent/>
        {/* <Header isTransparent={true} /> */}
        {/* <img
          src={
            'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgT/2gAMAwEAAhADEAAAAc4ibSxIh//EABgQAAIDAAAAAAAAAAAAAAAAAAASASBB/9oACAEBAAEFAsaBqf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAIQ/9oACAECAQE/AVP/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAXEAEBAQEAAAAAAAAAAAAAAAABACEQ/9oACAEBAAE/IUNbEI4xf//aAAwDAQACAAMAAAAQUM//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxCq/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPxAMq//EABoQAQADAQEBAAAAAAAAAAAAAAEAESGhUbH/2gAIAQEAAT8QOaWzyoAC+RLnIOw/WDJ//9k='
          }
        /> */}
        <Seo
          yoastHead={yoastHead}
          title={title}
          metaTitle={seo.title}
          lang={lang}
          description={seo.metaDesc}
          TwiterImage={
            seo?.twitterImage?.localFile?.childImageSharp?.fluid?.base64 
          }
        />

        <main>{children}</main>
      </div>
    </ApolloProvider>
  );
};

Layout.defaultProps = {
  transitionProps: {
    uri: '',
  },
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  transitionProps: PropTypes.shape({}),
};

export default Layout;
