import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import PopUpImage from '../images/popupCover.png';
import ExitButton from '../../static/assets/Exit.svg';
const PopUpComponent = () => {
  const [open, setOpen] = useState(false);
const [visible, setVisible] = React.useState(false);
useEffect(() => {
  let pop_status = localStorage.getItem('pop_status');
  setTimeout(() => {
    setOpen(true);
  }, 12000);
  if (!pop_status) {
    setVisible(true);
    localStorage.setItem('pop_status', 1);
  }
}, []);
if (!visible) return null;
  return (
    <Popup open={open}>
      <button
        aria-label="button"
        onClick={() => setVisible(false)}
        className="popup-section-button"
      >
        <ExitButton />
      </button>
      <div className="row">
        <div className="col-md-4 popup-section-col-image">
          <img src={PopUpImage} className="popup-section-image" alt="popup" />
        </div>
        <div className="col-md-8 popup-section-col">
          <div className="popup-section">
            <h1 className="popup-section-first-title">
              Download Our Free Report
            </h1>
            <p className="popup-section-title">
              The Three Essential Ingredients to Gathering Stakeholder Buy-in
            </p>
            <p className="popup-section-description">
              At Agreed, we know you want to be a valued change maker. Learn how
              you can ensure stakeholder buy-in and guarantee success for your
              ideas.
            </p>
            <Link
              to="https://cdn.forms-content.sg-form.com/8ed9b369-f232-11ec-9600-a211f32af6a3"
              className="popup-section-link"
              target="_blank"
            >
              Get the Report Now
            </Link>
          </div>
        </div>
      </div>
    </Popup>
  );
};
export default PopUpComponent;
