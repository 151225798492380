/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ description, title, metaTitle, TwiterImage }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
          }
        }
      }
    `
  );
  const {
    description: siteMetadataDescritption,
    siteUrl,
    image,
  } = site.siteMetadata;
  // console.log(siteMetadataDescritption, siteUrl, image);
  // const metaDescription = description || site.siteMetadata.description;
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <title>{title}</title>
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:image" content={SocialImage} /> */}
      <meta property="twitter:image" content={TwiterImage} />
      {/* <meta property='og:image' content={image}/> */}

      <meta name="description" content={siteMetadataDescritption} />
      <meta name="image" content={image} />

      {siteUrl && <meta property="og:url" content={siteUrl} />}
      {/* <meta property="og:image" content={image} /> */}
      {/* <script type="text/javascript">
        {` window.STONLY_WID = "33b06996-430d-11ec-83f1-062882f67cfe";`}
      </script>
      <script type="text/javascript">
        {` !function(s,t,o,n,l,y,w,g)
        {s.StonlyWidget ||
          (((w = s.StonlyWidget =
            function () {
              w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
            }).scriptPath = n),
          (w.queue = []),
          ((y = t.createElement(o)).async = !0),
          (g = new XMLHttpRequest()).open(
            'GET',
            n + 'version?v=' + Date.now(),
            !0
          ),
          (g.onreadystatechange = function () {
            4 === g.readyState &&
              ((y.src =
                n +
                'stonly-widget.js?v=' +
                (200 === g.status ? g.responseText : Date.now())),
              (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
          }),
          g.send())}
        (window,document,"script","https://stonly.com/js/widget/v2/");`}
      </script> */}
      <script type="text/javascript">
        {`   adroll_adv_id = "2J5IRJLYKZBFLL7SF7V3XQ";
    adroll_pix_id = "Y5OEGHPJERGEBC3AC5ZJAN";
    adroll_version = "2.0";

    (function(w, d, e, o, a) {
        w.__adroll_loaded = true;
        w.adroll = w.adroll || [];
        w.adroll.f = [ 'setProperties', 'identify', 'track' ];
        var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
                + "/roundtrip.js";
        for (a = 0; a < w.adroll.f.length; a++) {
            w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
                return function() {
                    w.adroll.push([ n, arguments ])
                }
            })(w.adroll.f[a])
        }

        e = d.createElement('script');
        o = d.getElementsByTagName('script')[0];
        e.async = 1;
        e.src = roundtripUrl;
        o.parentNode.insertBefore(e, o);
    })(window, document);
    adroll.track("pageView");`}
      </script>
      <script type="text/javascript">
        {`(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];
l.GlobalLeadinfoNamespace.push(i);l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};l[i].t=l[i].t||n;
l[i].q=l[i].q||[];o=e.createElement(a);f=e.getElementsByTagName(a)[0];o.async=1;o.src=d;f.parentNode.insertBefore(o,f);}
}(window,document,"script","https://cdn.leadinfo.net/ping.js","leadinfo","LI-623322DAEFB52")); `}
      </script>
      <script type="text/javascript">
        {`
function cf_chat_loader() {
  return {
    packet_code: '143df65dc6713c2d0ce6249a9bdeff90b3811344',
    app_url: 'https://app.chatfunnels.com/chat-client/',
    service_address: 'app.chatfunnels.com/api/chat-service/a'
  }
}
(function() {
  let el = document.createElement('script');
  el.async = true;
  el.src = 'https://app.chatfunnels.com/chat-client/chat-loader.js';
  if (window.document.body) {
    window.document.body.appendChild(el);
  } else {
    window.document.head.appendChild(el);
  }
}());
`}
      </script>
      <meta
        name="facebook-domain-verification"
        content="39yhc0wimolb8aixs86sxb6jk15st6"
      />
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: 'de',
  meta: [],
  description: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  // title: PropTypes.string.isRequired,
};

export default Seo;
